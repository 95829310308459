import React from 'react'
import './styles.module.css'

export default () => (
  <iframe
    src="//widgets.talentinc.com/critique/v2-iframe/?pt=kjlGZP8Sx7eZT&lang=en-gb"
    frameborder="0"
    width="100%"
    height="700px"
    title="topCv"
  ></iframe>
)
