import React from 'react'
import PageHelmet from '../components/PageHelmet'
import TopInterviewLarge from '../components/TopInterviewLarge'

const ResumeReviewPage = () => (
  <>
    <PageHelmet title="Get a free CV review" />
    <TopInterviewLarge />
  </>
)

export default ResumeReviewPage
